import { ResponseError } from '../../errors';

import { IApplicationContext } from '../../types/applicationContext';
import { IPromoBanners } from '../../types/newbuildingBanners/newbuildingBanners';
import {
  fetchGetPromoBanners,
  TGetPromoBannersResponse,
} from '../../repositories/newbuilding-promos/v1/get-promo-banners';

const DEGRADATION_TELEMETRY_KEY = 'degradation.recommendations.desktop';

export async function getNewbuildingBanners(context: IApplicationContext): Promise<IPromoBanners> {
  const {
    httpApi,
    logger,
    telemetry,
    custom: { subdomain },
  } = context;

  try {
    const response: TGetPromoBannersResponse = await fetchGetPromoBanners({
      httpApi,
      parameters: {
        subdomain,
      },
    });

    if (response.statusCode !== 200) {
      throw new ResponseError({
        domain: 'getDesktopRecommendations',
        message: response.response.message || 'Код ответа не 200',
        details: { errors: JSON.stringify(response.response.errors) },
      });
    }

    return response.response as IPromoBanners;
  } catch (error) {
    telemetry.increment(DEGRADATION_TELEMETRY_KEY);
    logger.warning(error, {
      domain: 'getNewbuildingRecommendations',
      message: 'Не удалось получить список рекомендаций',
    });

    // Повторное выкидывание исключения нужно для asyncThunk
    throw error;
  }
}
