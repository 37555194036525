import { ItemsCarousel } from '../../components/NewbuildingBannersCarousel/ItemsCarousel';
import * as React from 'react';
import { TBanners } from '../../types/newbuildingBanners/banners';
import { Banner } from '../NewbuildingRecommendations/Banner';
import { bannerTypeToGradientTheme } from '../../components/NewbuildingBanners/utils/bannerTypeToTheme';
import { useNewbuildingBanners } from './useNewbuildingBanners';
import { ActionAfterViewed } from '@cian/action-after-viewed-component';
import { ELoadingStatus } from '../../types/loadingStatus';
import * as styles from '../../components/NewbuildingBannersCarousel/ItemsCarousel/ItemsCarousel.css';

export const NewbuildingBannersCarousel = () => {
  const { banners, onBannerClick, onBannerShow, bannersStatus } = useNewbuildingBanners();

  const renderOutside = React.useCallback(() => {
    const items = banners.map((item: TBanners, idx) => {
      return (
        <li key={`banner-${idx}`}>
          <ActionAfterViewed
            parentBlockCssClass={idx !== 0 ? styles['newbuilding-banners-container'] : undefined}
            callback={onBannerShow({ type: item.type })}
          >
            <Banner onClick={onBannerClick({ type: item.type })} data={item} themeMapper={bannerTypeToGradientTheme} />
          </ActionAfterViewed>
        </li>
      );
    });

    return items;
  }, [banners]);

  if (bannersStatus === ELoadingStatus.Loading || bannersStatus === ELoadingStatus.Failure) {
    return null;
  }

  return <ItemsCarousel arrowOffset={10} scrollVisible renderOutside={renderOutside} />;
};
