import { TBanners, TBannerTypes } from '../../../types/newbuildingBanners/banners';

export interface IBannersWeight {
  type: TBannerTypes;
  weight: number;
}

const bannersWeight: IBannersWeight[] = [
  { type: 'lastChanceDiscount', weight: 1 },
  { type: 'topPromos', weight: 1 },
  { type: 'allPromos', weight: 1 },
  { type: 'kpn', weight: 1 },
  { type: 'promosFlight', weight: 1000 },
  { type: 'topPromo', weight: 1 },
];

const permittedBanners = ['lastChanceDiscount', 'topPromos', 'allPromos', 'kpn', 'promosFlight'];

export const getRandomBanner = ({ banners }: { banners: TBanners[] }): TBannerTypes[] => {
  const currentTypes = banners.map(item => item.type);

  let currentBannerWeights = bannersWeight.filter(item => {
    return currentTypes.includes(item.type) && permittedBanners.includes(item.type);
  });

  const bannerTypes = [];

  while (currentBannerWeights.length) {
    let totalWeights = 0;
    for (let i = 0; i < currentBannerWeights.length; i++) {
      totalWeights = totalWeights + currentBannerWeights[i].weight;
    }

    let random = Math.random() * totalWeights;
    let bannerType: TBannerTypes | null = null;

    for (let i = 0; i < currentBannerWeights.length; i++) {
      const banner: IBannersWeight = currentBannerWeights[i];
      if (random < banner.weight) {
        bannerType = banner.type as TBannerTypes;
        break;
      }
      random = random - banner.weight;
    }

    bannerTypes.push(bannerType);
    currentBannerWeights = currentBannerWeights.filter(item => item.type !== bannerType);
  }

  return bannerTypes.filter((item: TBannerTypes | null): item is TBannerTypes => !!item);
};
