import * as React from 'react';
import { Label, LinkButton } from '@cian/ui-kit';

import { TBannerTheme } from '../../../components/NewbuildingBanners/utils/bannerTypeToTheme';
import { ITopPromoBanner } from '../../../types/newbuildingBanners/topPromoBanner';
import { TopPromoBanner as TopPromoBannerComponent } from '../../../components/NewbuildingBanners/TopPromoBanner/TopPromoBanner';

interface ITopPromoBannerProps extends ITopPromoBanner {
  theme: TBannerTheme;
  onClick(): void;
  linkUrl?: string;
}

const DEFAULT_ACTION_TEXT = 'Смотреть ЖК по акции';

export const TopPromoBanner = ({ labels, action, title, theme, popupUrl, provider, onClick }: ITopPromoBannerProps) => {
  return (
    <TopPromoBannerComponent
      theme={theme}
      url={popupUrl}
      labels={
        <>
          {labels.map(({ text }) => (
            <Label key={text} background="decorative-theme-white" color="control-main-primary-default">
              {text}
            </Label>
          ))}
        </>
      }
      provider={provider.name}
      imageUrl={provider.logoUrl}
      footer={
        <LinkButton href={popupUrl} target="_blank" onClick={onClick} fullWidth={true}>
          {action.title || DEFAULT_ACTION_TEXT}
        </LinkButton>
      }
      title={title}
      onClick={onClick}
    />
  );
};
