import * as React from 'react';
import { LinkButton, UIHeading1 } from '@cian/ui-kit';

import * as styles from './RecommendationsListDesktopWithNewbuildings.css';
import { useInView } from '@cian/valuation-utils-component';
import { useVisitedOffersContext } from '@cian/frontend-visited-offers-widget';

interface IRecommendationsListDesktopProps {
  onShow?(): void;
  onMoreClick?(): void;
  children?: React.ReactNode;
  secondRow?: React.ReactNode;
  newbuildingBanners: React.ReactNode;
}

export const RecommendationsListDesktopWithNewbuildings: React.FC<IRecommendationsListDesktopProps> = ({
  onShow,
  children,
  onMoreClick,
  secondRow,
  newbuildingBanners,
}) => {
  const [isShown, setIsShown] = React.useState(false);
  const { isLoaded: isVisitedOffersLoaded } = useVisitedOffersContext();

  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  React.useEffect(() => {
    if (inView && onShow && !isShown && isVisitedOffersLoaded) {
      onShow();
      setIsShown(true);
    }
  }, [inView, onShow, isShown, isVisitedOffersLoaded]);

  return (
    <div ref={ref}>
      <UIHeading1>Могут подойти</UIHeading1>
      <div className={styles['list']}>
        {React.Children.map(children, (child, index) => {
          if (index === 2 && newbuildingBanners) {
            return <>{newbuildingBanners}</>;
          }

          if (index === 3) {
            return (
              <>
                {child}
                <div className={styles['row']}>{secondRow}</div>
              </>
            );
          } else {
            return child;
          }
        })}
      </div>
      <LinkButton
        data-name="MoreLink"
        size="M"
        theme="fill_secondary"
        fullWidth
        onClick={onMoreClick}
        href="/recommendations/"
      >
        Посмотреть ещё
      </LinkButton>
    </div>
  );
};
