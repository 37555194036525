import { IAppState } from '../../types/redux';
import { isNewbuildingRecommendationsEnabledSelector } from './isNewbuildingRecommendationsEnabled';

import { selectExperiments } from '../selectExperiments';
import { isAbUseExperimentGroup } from '../../utils/isAbUseExperimentGroup';

export const NEWBUILDING_ENTRY_POINTS_MAIN_DESKTOP_ENABLED = 'newbuilding_entry_points_main_desktop';

export const selectNewbuildingRecommendationCExperimentEnabled = (state: IAppState): boolean => {
  const isEnabled = isNewbuildingRecommendationsEnabledSelector(state);

  const abUseExperiments = selectExperiments(state);

  return isAbUseExperimentGroup(abUseExperiments, NEWBUILDING_ENTRY_POINTS_MAIN_DESKTOP_ENABLED, 'C') && isEnabled;
};

export const selectNewbuildingRecommendationBExperimentEnabled = (state: IAppState): boolean => {
  const isEnabled = isNewbuildingRecommendationsEnabledSelector(state);

  const abUseExperiments = selectExperiments(state);

  return isAbUseExperimentGroup(abUseExperiments, NEWBUILDING_ENTRY_POINTS_MAIN_DESKTOP_ENABLED, 'B') && isEnabled;
};

export const selectNewbuildingRecommendationBCExperimentEnabled = (state: IAppState): boolean => {
  const isEnabled = isNewbuildingRecommendationsEnabledSelector(state);

  const abUseExperiments = selectExperiments(state);

  return (
    (isAbUseExperimentGroup(abUseExperiments, NEWBUILDING_ENTRY_POINTS_MAIN_DESKTOP_ENABLED, 'C') ||
      isAbUseExperimentGroup(abUseExperiments, NEWBUILDING_ENTRY_POINTS_MAIN_DESKTOP_ENABLED, 'B')) &&
    isEnabled
  );
};
