//newbuildingBanners

import { createSlice } from '@reduxjs/toolkit';
import { ELoadingStatus } from '../../types/loadingStatus';
import { createAppAsyncThunk } from '../../utils/redux/createAppAsyncThunk';
import { getNewbuildingBanners } from '../../services/getNewbuildingBanners/getNewbuildingBanners';
import { IPromoBanners } from '../../types/newbuildingBanners/newbuildingBanners';

const initialState = {
  data: null as IPromoBanners | null,
  status: ELoadingStatus.Loading,
};

export const fetchNewbuildingBanners = createAppAsyncThunk('newbuildingBanners/fetch', (_, { extra: context }) => {
  return getNewbuildingBanners(context);
});

const newbuildingBannersSlice = createSlice({
  name: 'newbuildingBanners',
  reducers: {},
  initialState,
  extraReducers(builder) {
    builder.addCase(fetchNewbuildingBanners.pending, state => {
      state.status = ELoadingStatus.Loading;
    });
    builder.addCase(fetchNewbuildingBanners.fulfilled, (state, { payload }) => {
      state.status = ELoadingStatus.Success;
      state.data = payload;
    });
    builder.addCase(fetchNewbuildingBanners.rejected, state => {
      state.status = ELoadingStatus.Failure;
    });
  },
});

export const newbuildingBannersReducer = newbuildingBannersSlice.reducer;
