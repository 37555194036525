import * as React from 'react';

import { useAppDispatch, useAppSelector as useSelector } from '../../types';
import { useApplicationContext } from '../../utils/applicationContext';
import { selectRegionId } from '../../selectors/selectRegionId';
import { fetchNewbuildingBanners } from '../../slices/newbuildingBanners/newbuildingBannersSlice';
import { selectNewbuildingBanners } from '../../selectors/newbuildings/selectNewbuildingBanners';
import { onBannerClickAnalytic, onBannerShowAnalytic } from '../NewbuildingRecommendations/analytics/analytic';
import { selectNewbuildingBannersStatus } from '../../selectors/newbuildings';
import { TBannerTypes } from '../../types/newbuildingBanners/banners';

export const useNewbuildingBanners = () => {
  const {
    custom: { isMobile },
    config,
  } = useApplicationContext();

  const dispatch = useAppDispatch();
  const regionId = useSelector(selectRegionId);
  const bannersData = useSelector(selectNewbuildingBanners);
  const bannersStatus = useSelector(selectNewbuildingBannersStatus);
  const isFlightEnabled = config.get('NewbuildingPromos.12WaysToBuyFlatFlight.Enabled');
  const banners = bannersData
    ? isFlightEnabled
      ? [bannersData.allPromos, ...bannersData.promos, ...bannersData.compilations]
      : [...bannersData.promos, ...bannersData.compilations, bannersData.allPromos]
    : [];

  const onBannerClick = React.useCallback(
    ({ type }: { type: TBannerTypes }) =>
      () => {
        onBannerClickAnalytic({ type });
      },
    [],
  );

  const onBannerShow = React.useCallback(
    ({ type }: { type: TBannerTypes }) =>
      () => {
        onBannerShowAnalytic({ type });
      },
    [],
  );

  React.useEffect(() => {
    dispatch(fetchNewbuildingBanners());
  }, [dispatch]);

  return React.useMemo(
    () => ({
      banners,
      bannersStatus,
      onBannerClick,
      onBannerShow,
      isMobile,
      regionId,
    }),
    [banners, bannersStatus, onBannerClick, onBannerShow, isMobile, regionId],
  );
};
