import * as React from 'react';
import { throttle } from 'throttle-debounce';

import {
  SCROLL_THROTTLE_DELAY,
  SCROLL_CLICK_DEAD_ZONE,
  RESIZE_THROTTLE_DELAY,
  SCROLL_ANIMATION_DELAY,
  SCROLL_VISIBLE_DEAD_ZONE,
} from './constants';
import { ArrowButton } from './components/ArrowButton';

import {
  getFullLeftScrollOffset,
  getFullRightScrollOffset,
  getLeftScrollOffset,
  getRightScrollOffset,
} from './helpers';
import { scrollToPos, IScrollToPos } from './helpers/scrollTo';
import { IArrowButtonProps, TScrollSize } from './types';
import * as styles from './ItemsCarousel.css';
import * as classNames from 'classnames';

export interface IItemsCarouselProps {
  arrowButton?: React.ComponentType<IArrowButtonProps>;
  arrowOffset?: number;
  arrowVerticalOffset?: number;
  onItemClick?(index: number): void;
  renderOutside?(): React.ReactNode;
  scrollVisible?: boolean;
  styles?: string;
  size?: TScrollSize;
}

export type TItemsCarouselProps = React.PropsWithChildren<IItemsCarouselProps>;

interface IItemsCarouselState {
  offsetWidth: number;
  scrollLeft: number;
  scrollWidth: number;
}

export class ItemsCarousel extends React.Component<TItemsCarouselProps, IItemsCarouselState> {
  private scrollRef = React.createRef<HTMLUListElement>();
  private startX: number;
  private scrollLeft: number;
  private scrollToPos: IScrollToPos | null = null;
  private isScrolling = false;
  private isDragging = false;

  public state = {
    offsetWidth: 0,
    scrollLeft: 0,
    scrollWidth: 0,
  };

  public componentDidMount() {
    this.resetScrollState();
    window.addEventListener('resize', this.handleResize);
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  private get leftArrowVisible() {
    const { scrollLeft } = this.state;

    return scrollLeft > SCROLL_VISIBLE_DEAD_ZONE;
  }

  private get rightArrowVisible() {
    const { scrollLeft, offsetWidth, scrollWidth } = this.state;

    return scrollLeft < scrollWidth - offsetWidth - SCROLL_VISIBLE_DEAD_ZONE;
  }

  public render() {
    const { arrowOffset, arrowVerticalOffset, arrowButton: ArrowComponent = ArrowButton } = this.props;

    return (
      <div className={styles['wrapper']}>
        <ul
          className={classNames(styles['newbuilding-banners-container'], this.props.styles)}
          ref={this.scrollRef}
          onMouseDown={this.handleMouseDown}
          onScroll={this.throttledHandleScroll}
        >
          {this.props.renderOutside
            ? this.props.renderOutside()
            : React.Children.map(this.props.children, (child, i) => child && <li key={i}>{child}</li>)}
        </ul>
        {this.leftArrowVisible && (
          <ArrowComponent
            direction={-1}
            offset={arrowOffset}
            verticalOffset={arrowVerticalOffset}
            onClick={this.onLeftArrowClick}
          />
        )}
        {this.rightArrowVisible && (
          <ArrowComponent
            direction={1}
            offset={arrowOffset}
            verticalOffset={arrowVerticalOffset}
            onClick={this.onRightArrowClick}
          />
        )}
      </div>
    );
  }

  private resetScrollState = () => {
    const container = this.scrollRef.current;

    /* istanbul ignore else */
    if (container) {
      const { offsetWidth, scrollLeft, scrollWidth } = container;
      this.setState({ offsetWidth, scrollLeft: Math.ceil(scrollLeft), scrollWidth });
    }
  };

  private handleResize = throttle(RESIZE_THROTTLE_DELAY, this.resetScrollState);

  private handleMouseDown = (e: React.MouseEvent) => {
    e.preventDefault();
    this.stopScrolling();

    const container = this.scrollRef.current;

    /* istanbul ignore else */
    if (container) {
      this.startX = e.pageX;
      this.scrollLeft = container.scrollLeft;

      window.addEventListener('mousemove', this.handleMove);
      window.addEventListener('mouseup', this.handleMoveEnd);
    }
  };

  private handleMoveEnd = () => {
    window.removeEventListener('mousemove', this.handleMove);
    window.removeEventListener('mouseup', this.handleMoveEnd);
  };

  private preventDraggableClick = (e: MouseEvent) => {
    e.preventDefault();
    e.stopImmediatePropagation();

    /* istanbul ignore else */
    if (this.scrollRef.current) {
      this.scrollRef.current.removeEventListener('click', this.preventDraggableClick, { capture: true });
      this.isDragging = false;
    }
  };

  private stopDraggableClick = () => {
    /* istanbul ignore else */
    if (this.scrollRef.current) {
      this.scrollRef.current.addEventListener('click', this.preventDraggableClick, { capture: true });
    }
  };

  private handleMove = (e: MouseEvent) => {
    const container = this.scrollRef.current;

    /* istanbul ignore else */
    if (container) {
      const walk = e.pageX - this.startX;

      if (Math.abs(walk) >= SCROLL_CLICK_DEAD_ZONE && !this.isDragging) {
        this.isDragging = true;
        this.stopDraggableClick();
      }

      let scrollLeft = this.scrollLeft - walk;
      const maxScrollLeft = container.scrollWidth - container.offsetWidth;

      if (scrollLeft < 0) {
        scrollLeft = 0;
      }

      if (scrollLeft > maxScrollLeft) {
        scrollLeft = maxScrollLeft;
      }

      container.scrollLeft = scrollLeft;
    }
  };

  private handleScroll = () => {
    const container = this.scrollRef.current;

    /* istanbul ignore else */
    if (container) {
      if (!this.isScrolling) {
        this.setState({ scrollLeft: Math.ceil(container.scrollLeft) });
      }
    }
  };

  private throttledHandleScroll = throttle(SCROLL_THROTTLE_DELAY, this.handleScroll);

  private onRightArrowClick = () => {
    const container = this.scrollRef.current;
    const { scrollLeft } = this.state;
    const { scrollVisible } = this.props;

    /* istanbul ignore else */
    if (container) {
      const scrollOffset = scrollVisible
        ? getFullRightScrollOffset(container, scrollLeft)
        : getRightScrollOffset(container, scrollLeft);

      this.stopScrolling();
      this.scrollTo(scrollOffset);
    }
  };

  private onLeftArrowClick = () => {
    const container = this.scrollRef.current;
    const { scrollLeft } = this.state;
    const { scrollVisible } = this.props;

    /* istanbul ignore else */
    if (container) {
      const scrollOffset = scrollVisible
        ? getFullLeftScrollOffset(container, scrollLeft)
        : getLeftScrollOffset(container, scrollLeft);

      this.stopScrolling();
      this.scrollTo(scrollOffset);
    }
  };

  private scrollTo = (scrollOffset: number) => {
    const container = this.scrollRef.current;

    /* istanbul ignore else */
    if (container) {
      this.isScrolling = true;
      this.setState({ scrollLeft: scrollOffset });

      container.addEventListener('scrollEnd', this.stopScrolling);
      this.scrollToPos = scrollToPos(container, scrollOffset, SCROLL_ANIMATION_DELAY);
    }
  };

  private stopScrolling = () => {
    if (this.scrollToPos) {
      this.scrollToPos.cancel();
      this.scrollToPos = null;
    }

    this.isScrolling = false;

    const container = this.scrollRef.current;

    /* istanbul ignore else */
    if (container) {
      container.removeEventListener('scrollEnd', this.stopScrolling);
    }
  };
}
